module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Senior SEO","short_name":"Senior SEO","start_url":"/","background_color":"#ffffff","theme_color":"#24292e","display":"minimal-ui","icon":"src/assets/contact-riad-joseph-SEO.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"571ea178730ca4b154616c7b3e7b5d91"},
    },{
      plugin: require('../node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
